import { useState } from "react";
import { isMobile } from "react-device-detect";
const MobileDownload = () => {
  const [downloadOptions, setDownloadOptions] = useState({
    android: {
      isB2BApp: true,
      b2bLink: "https://play.google.com/store/apps/details?id=com.ahd.agent",
      b2cLink: "https://play.google.com/store/apps/details?id=com.minhtrung",
    },
    ios: {
      isB2BApp: true,
      b2bLink: "https://apps.apple.com/vn/app/ahd-b2b/id1634752180",
      b2cLink: "https://apps.apple.com/us/app/ahd-b2c/id1634947278",
    },
  });

  const setAndroidDownloadOptions = (isB2BApp) => {
    var newOptions = { ...downloadOptions };
    if (newOptions.android.isB2BApp !== isB2BApp) {
      newOptions.android.isB2BApp = isB2BApp;
      setDownloadOptions(newOptions);
    }
  };
  const setIOSDownloadOptions = (isB2BApp) => {
    var newOptions = { ...downloadOptions };
    if (newOptions.ios.isB2BApp !== isB2BApp) {
      newOptions.ios.isB2BApp = isB2BApp;
      setDownloadOptions(newOptions);
    }
  };
  return (
    <section className="page-section bg-dark text-white" id="download">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5 justify-content-center">
          <div className="col-lg-10 text-center">
            <h2 className="text-white mt-0">Bạn muốn mua hàng?</h2>
            <hr className="divider" />
            <p className="text-white-75 mb-4">
              Chúng tôi Công ty Cổ phần Tập Đoàn Hoàng Minh Trung hoan nghênh
              mọi sự hợp tác dù đó là với những Hộ kinh Doanh, trại chăn nuôi,
              nhà vườn, đơn vị sản xuất, hay các đơn vị kinh doanh chuyên
              nghiệp.
            </p>
            <p className="text-white-75 mb-4">
              Để xem đầy đủ các sản phẩm chúng tôi cung cấp, vui lòng tải App
              cho Đại Lý / Người Dùng theo hướng dẫn bên dưới.
            </p>
            <hr className="divider" />
          </div>
        </div>
        <div className="row gx-4 gx-lg-5 mt-5">
          <div className="d-none d-lg-block col-lg-4">
            <img
              className="img-fluid"
              src={require("../assets/img/content/phone.png")}
              alt="..."
            />
          </div>
          <div className="col-lg-8">
            <h2 className="text-white">
              <i className="bi bi-android2"></i>&nbsp;Tải app Android
            </h2>
            <div className="row mt-4 justify-content-around">
              <div className="col-md-7">
                <div className="d-grid">
                  <button
                    className={`btn btn-xl ${
                      downloadOptions.android.isB2BApp
                        ? "btn-primary"
                        : "btn-light"
                    }`}
                    onClick={() => setAndroidDownloadOptions(true)}
                  >
                    <i className="bi bi-person-lines-fill"></i>&nbsp;App cho Đại
                    Lý
                  </button>
                </div>
                <div className="d-grid mt-3">
                  <button
                    className={`btn btn-xl ${
                      !downloadOptions.android.isB2BApp
                        ? "btn-primary"
                        : "btn-light"
                    }`}
                    onClick={() => setAndroidDownloadOptions(false)}
                  >
                    <i className="bi bi-person"></i>&nbsp;App cho Người Dùng
                  </button>
                </div>
              </div>
              <div className="col-md-5 mt-2 text-center">
                {downloadOptions.android.isB2BApp && (
                  <img
                    className="img-qrcode"
                    src={require("../assets/img/qrcode-dl/qr_dl_ahd_b2b_android.png")}
                    alt="..."
                  />
                )}
                {!downloadOptions.android.isB2BApp && (
                  <img
                    className="img-qrcode"
                    src={require("../assets/img/qrcode-dl/qr_dl_ahd_b2c_android.png")}
                    alt="..."
                  />
                )}
                {isMobile && (
                  <a
                    style={{ display: "block", marginTop: "0.5rem" }}
                    href={`${
                      downloadOptions.android.isB2BApp
                        ? downloadOptions.android.b2bLink
                        : downloadOptions.android.b2cLink
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click để tải app
                  </a>
                )}
              </div>
            </div>
            <h2 className="text-white mt-5">
              <i className="bi bi-apple"></i>&nbsp;Tải app iOS
            </h2>
            <div className="row mt-4 justify-content-around">
              <div className="col-md-7">
                <div className="d-grid">
                  <button
                    className={`btn btn-xl ${
                      downloadOptions.ios.isB2BApp ? "btn-primary" : "btn-light"
                    }`}
                    onClick={() => setIOSDownloadOptions(true)}
                  >
                    <i className="bi bi-person-lines-fill"></i>&nbsp;App cho Đại
                    Lý
                  </button>
                </div>
                <div className="d-grid mt-3">
                  <button
                    className={`btn btn-xl ${
                      downloadOptions.ios.isB2BApp ? "btn-light" : "btn-primary"
                    }`}
                    onClick={() => setIOSDownloadOptions(false)}
                  >
                    <i className="bi bi-person"></i>&nbsp;App cho Người Dùng
                  </button>
                </div>
              </div>
              <div className="col-md-5 mt-2 text-center">
                {downloadOptions.ios.isB2BApp && (
                  <img
                    className="img-qrcode"
                    src={require("../assets/img/content/qr_dl_ahd_b2b_ios.png")}
                    alt="..."
                  />
                )}
                {!downloadOptions.ios.isB2BApp && (
                  <img
                    className="img-qrcode"
                    src={require("../assets/img/content/qr_dl_ahd_b2c_ios.png")}
                    alt="..."
                  />
                )}
                {isMobile && (
                  <a
                    style={{ display: "block", marginTop: "0.5rem" }}
                    href={`${
                      downloadOptions.ios.isB2BApp
                        ? downloadOptions.ios.b2bLink
                        : downloadOptions.ios.b2cLink
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click để tải app
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MobileDownload;
