import { useState, useEffect } from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';

const contactSchema = yup.object().shape({
    name: yup.string()
    .required('Bạn hãy nhập tên')
    .min(4, ({min}) => `Tên phải có ít nhất ${min} ký tự`),
    phone: yup
    .string()
    .required('Bạn hãy nhập số điện thoại')
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Số điện thoại không hợp lệ',
    ),
    email: yup.string().email('Bạn hãy nhập email hợp lệ'),
    content: yup.string().required('Bạn hãy nhập nội dung')
    .min(50, ({min}) => `Nội dung phải có ít nhất ${min} ký tự`),
});

const ContactUs = () => {
    const [isSendingContact, setIsSendingContact] = useState(false);
    const [sendResult, setSendResult] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            subject: '',
            content: '',
        },
        validationSchema: contactSchema,
        onSubmit: async (values) => {
            console.log("Submiting data", values);
            setIsSendingContact(true);
            setSendResult(null);
            
            fetch(`${process.env.REACT_APP_API_URL}/front/contacts/send`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
            })
            .then((res) => res.json())
            .then(json => {
                console.log('result of sending contact info', json);
                if(json === true){
                    formik.resetForm();
                    setSendResult(true);
                }
                setIsSendingContact(false);
            })
            .catch(()=>{
                setIsSendingContact(false);
                setSendResult(false);
            })
        },
    });



	return (
		<section className="page-section" id="contact">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 col-xl-6 text-center">
                        <h2 className="mt-0">Liên Hệ</h2>
                        <hr className="divider" />
                        <p className="mb-5">TP.THANH HÓA. TỈNH THANH HÓA. VIỆT NAM<br />
						Lô B06-07 Đường đại Lộ Hùng Vương, phường Nam Ngạn<br />
						Hotline: 1900866615 – 0987469469 (A chiến)
						</p>
                    </div>
                </div>
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-6">
                        {/* <!-- * * * * * * * * * * * * * * *-->
                        <!-- * * SB Forms Contact Form * *-->
                        <!-- * * * * * * * * * * * * * * *-->
                        <!-- This form is pre-integrated with SB Forms.-->
                        <!-- To make this form functional, sign up at-->
                        <!-- https://startbootstrap.com/solution/contact-forms-->
                        <!-- to get an API token!--> */}
                        <form id="contactForm">
                            <div className="form-floating mb-3">
                                <input className="form-control" id="name" type="text" placeholder="Nhập Họ Tên..." value={formik.values.name} onChange={formik.handleChange("name")}/>
                                <label htmlFor="name">Họ Tên</label>
                                {
                                    formik.errors.name && formik.touched.name 
                                    && <div className="invalid-feedback" style={{display:'block'}}>{formik.errors.name}</div>
                                }
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="email" type="email" placeholder="name@example.com" value={formik.values.email} onChange={formik.handleChange("email")} />
                                <label htmlFor="email">Địa chỉ Email</label>
                                {
                                    formik.errors.email && formik.touched.email 
                                    && <div className="invalid-feedback" style={{display:'block'}}>{formik.errors.email}</div>
                                }
                            </div>
                            
                            <div className="form-floating mb-3">
                                <input className="form-control" id="phone" type="tel" placeholder="(123) 456-7890" value={formik.values.phone} onChange={formik.handleChange("phone")} />
                                <label htmlFor="phone">Số điện thoại</label>
                                {
                                    formik.errors.phone && formik.touched.phone 
                                    && <div className="invalid-feedback" style={{display:'block'}}>{formik.errors.phone}</div>
                                }
                            </div>
                        
                            <div className="form-floating mb-3">
                                <textarea className="form-control" id="message" type="text" placeholder="Enter your message here..." style={{height: '10rem'}} value={formik.values.content} onChange={formik.handleChange("content")}></textarea>
                                <label htmlFor="message">Nội dung</label>
                                {
                                    formik.errors.content && formik.touched.content 
                                    && <div className="invalid-feedback" style={{display:'block'}}>{formik.errors.content}</div>
                                }
                            </div>
                    
                            <div className={sendResult != null && sendResult === true ? '' : "d-none"}>
                                <div className="text-center mb-3">
                                    <div className="fw-bolder">Đã gửi thành công!</div>
                                </div>
                            </div>
                       
                            <div className={sendResult != null && sendResult === false ? '' : "d-none"}><div className="text-center text-danger mb-3">Lỗi. Không thể gửi được!</div></div>
                            
                            <div className="d-grid"><button disabled={isSendingContact} className="btn btn-primary btn-xl" type="button" onClick={formik.handleSubmit}>Gửi</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
	)
}
export default ContactUs