const AboutUs = () => {
	return (
		<>
			<section className="page-section bg-dark text-white" id="about">
				<div className="container px-4 px-lg-5">
					<div className="row gx-4 gx-lg-5 justify-content-center">
						<div className="text-center">
							<h2 className="text-white mt-0">Về Chúng Tôi!</h2>
							<hr className="divider" />
						</div>					
						<div className="col-lg-5"><img className="img-fluid" src={require("../assets/img/content/about-1.jpg")} alt="..." /></div>
						<div className="col-lg-7">
							<p className="text-white-75 mb-4">Công ty Cổ phần Tập đoàn Hoàng Minh Trung là đơn vị hoạt động đa lĩnh vực trong ngành sản xuất, liên kết sản xuất, liên kết nhập khẩu, liên kết chăn nuôi, liên kết chế biến.</p>
							<p className="text-white-75 mb-4">Với hơn 10 năm kinh nghiệm trong ngành từ những người đứng đầu, đến nay Công ty Cổ phần Tập đoàn Hoàng Minh Trung đã hoạt động ổn định và đang phát triển mạnh mẽ trên 03 lĩnh vực: <span className="text-primary"><strong>Sản xuất, chế biến các sản phẩm An toàn; Sản xuất thực phẩm An toàn; cung cấp giải pháp mua bán trên nền tảng B2B2C uy tín</strong></span> Công ty Cổ phần Tập đoàn Hoàng Minh Trung ngày càng được khẳng định và đã có những vị trí nhất định trên App AHDB2B App AHD Đại lý, người tiêu dùng trên cả nước.</p>
						</div>
						<div className="text-center mt-5">
							<h2 className="text-white mt-0">Chúng tôi là ai?</h2>
							<hr className="divider" />
						</div>
						<div className="col-lg-7">
							<p className="text-white-75 mb-4">- Hệ thống thương mại điện tử gồm Web Quảng cáo điện tử AHD, nhà đầu tư sản tư sản phẩm, CEO, Sales, Đại lý online, người dùng online trên APP AHDB2B App AHD</p>
							<ol className="text-white-75">
								<li>Mô hình tiếp thị liên kết Thiên về (Bên mua)</li>
								<li>Mô hình kinh doanh Sàn thương mại điện tử B2B2C thiên về (Bên bán)</li>
								<li>Mô hình kinh doanh quảng cáo</li>
								<li>Hình thức đăng ký cửa hàng Online (trả phí hàng tháng)</li>
							</ol>
						</div>
						<div className="col-lg-5"><img className="img-fluid" src={require("../assets/img/content/about-2.jpg")} alt="..." /></div>
						<div className="text-center mt-5">
							<h2 className="text-white mt-0">Chúng Tôi Làm Gì?</h2>
							<hr className="divider" />
						</div>					
						<div className="col-lg-5"><img className="img-fluid" src={require("../assets/img/content/about-3.jpg")} alt="..." /></div>
						<div className="col-lg-7">
							<ul className="text-white-75">
								<li>Đưa sản phẩm lên app B2b - Đưa sản phẩm lên app B2c</li>
								<li>Quảng cáo sản phẩm trên web - Quảng cáo sản phẩm trên Youtube - Quảng cáo trên Facebook - Quảng cáo trên Zalo</li>
							</ul>
							<div className="text-center mt-5"><button type="button" className="btn btn-primary btn-xl" data-bs-toggle="modal" data-bs-target="#aboutDetails">Chi Tiết</button></div>
						</div>
					</div>
				</div>
			</section>

		{/* <!-- About Details Modal--> */}
		<div className="modal fade" id="aboutDetails" tabIndex="-1" aria-labelledby="aboutDetailsLabel" aria-hidden="true">
		  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
			<div className="modal-content">
			  <div className="modal-header">
				<h5 className="modal-title" id="aboutDetailsLabel">Chúng Tôi Làm Gì?</h5>
				<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
			  </div>
			  <div className="modal-body">
				<ul>
					<li>Đưa sản phẩm lên app B2b - Đưa sản phẩm lên app B2c</li>
					<li>Quảng cáo sản phẩm trên web - Quảng cáo sản phẩm trên Youtube - Quảng cáo trên Facebook - Quảng cáo trên Zalo</li>
				</ul>
				<p className="mb-4">- Ba xu hướng chính từ thị trường dẫn đến sự dịch chuyển sang mô hình B2B2C</p>
				<p className="mb-4">- Bao gồm xu hướng khách hàng là trọng tâm, xu hướng phát triển của thương mại điện tử. Điều này mang lại những lợi ích đáng kể cho các doanh nghiệp sản xuất, nhà Vườn, trại chăn nuôi, đơn vị nhập khẩu. Trước đây hầu hết các doanh nghiệp sản xuất, nhà Vườn, trại chăn nuôi, đơn vị nhập khẩu  không phải chú trọng đến vấn đề trải nghiệm khách hàng và chỉ phải tập trung vào sản xuất sản phẩm theo các đơn hàng.- Nhưng trong thị trường cạnh tranh hiện nay, khi khách hàng đang trở thành trung tâm của các hoạt động doanh nghiệp, tầm quan trọng của khách hàng cũng trở nên lớn hơn trong cả môi trường B2B (doanh nghiệp với doanh nghiệp)</p>
				<p className="mb-4">- B2B2C là viết tắt của cụm từ Business To Business To Customer Là mô hình kinh doanh có sự hợp tác giữa hai doanh nghiệp (B2B) để tạo ra và cung cấp sản phẩm, dịch vụ đến tay người tiêu dùng cuối (B2C). Cụ thể, mô hình B2B2C Sự di chuyển của luồng hàng hoá và thông tin trong mô hình B2B2C Giải thích cho sự chuyển dịch sang mô hình này là những tiến bộ về công nghệ, - Đã cho phép các doanh nghiệp sản xuất, nhà Vườn, trại chăn nuôi, đơn vị nhập khẩu  thu thập được các dữ liệu về khách hàng và thực hiện phân tích hành vi, nhu cầu của họ để có thể lên kế hoạch sản xuất, kinh doanh và bán hàng hiệu quả và tối ưu hơn.</p>
				<p className="mb-4">- Trong đó vấn đề quan trọng nhất là việc tích hợp các quy trình kinh doanh và quản lý của Doanh nghiệp với hệ thống công nghệ thông tin của đối tác kênh/nền tảng phân phối để tạo thành một hệ sinh thái để quản trị thông tin một cách xuyên suốt, như thông tin quản lý và giám sát đơn hàng, cập nhật tồn kho, thông tin giao hàng…Khi đó, các thông tin từ đối tác kênh/nền tảng phân phối sẽ được cập nhật thống nhất trong hệ thống thông tin của doanh nghiệp. Hỗ trợ bán hàng B2B và B2C. Kết nối chuỗi hoạt động từ theo dõi đơn hàng, thanh toán an toàn, quản lý dịch vụ khách hàng, tiếp thị và cung cấp cái nhìn tổng quát các tương tác với khách hàng.Xu Hướng phát Triển Công Nghệ các công nghệ đang được phát triển mạnh mẽ, hỗ trợ xây dựng mô hình. </p>
				<p className="mb-4">- Kiểm Soát Hình Ảnh Thương Hiệu, logo Và Sản Phẩm Tốt Hơn. Bán hàng và truyền thông thông tin thương hiệu, logo, sản phẩm trực tiếp tới khách hàng, nhà sản xuất sẽ truyền đạt thông tin một cách rõ ràng và đầy đủ hơn không phải lo lắng về việc những thông tin này bị truyền thông sai lệch, </p>
				<p className="mb-4">- Bên cạnh đó, trong việc tiếp nhận những ý kiến phản hồi từ khách hàng, đặc biệt là với những ý kiến đóng góp tiêu cực từ khách hàng, doanh nghiệp sản xuất, nhà Vườn, trại chăn nuôi, đơn vị nhập khẩu có thể đưa ra những phản hồi và đề xuất đền bù phù hợp một cách nhanh chóng tới khách hàng, Giúp giảm thiểu cảm giác tiêu cực và duy trì mối quan hệ gắn kết của khách hàng đối với thương hiệu, đồng thời, có những biện pháp xử lý ngay vấn đề gặp phải để tránh việc trải nghiệm tiêu cực đó bị lặp lại ở khách hàng kháctVà biết được số lượng đơn đặt hàng và đưa các sản phẩm ra thị trường nhanh hơn trực tiếp thông qua các nền tảng trực tuyến. Với B2B2C, các doanh nghiệp sản xuất bán hàng trực tiếp thông qua các nền tảng trực tuyến và có thể tiết kiệm chi phí thuê mặt bằng cửa hàng. </p>
				<p className="mb-4">- Những lợi ích nêu trên đều góp phần làm giảm chi phí và tăng biên lợi nhuận cho doanh nghiệp sản xuất, nhà Vườn, trại chăn nuôi, đơn vị nhập khẩu. Bên cạnh đó còn rất nhiều những lợi ích khác đem lại sự gia tăng trong lợi nhuận và doanh thu, ví dụ như khả năng bán hàng với vốn kiến thức đầy đủ về ngành hàng giúp doanh nghiệp bán chéo và bán hàng gia tăng với những tư vấn phù hợp về các sản phẩm/ dịch vụ liên quan, đi kèm và nâng cấp. Các lợi ích gián tiếp khác có thể kể đến ví dụ như với kế hoạch sản xuất phù hợp, chi phí sản xuất cũng được tối ưu hơn, lượng hàng tồn và lượng hàng cần giảm giá để bán cũng sẽ giảm, tiết kiệm được chi phí lưu kho và chi phí hàng tồn.</p>
			  </div>
			  <div className="modal-footer">
				<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Đóng</button>
			  </div>
			</div>
		  </div>
		</div>
		</>
		
	)
}
export default AboutUs;