/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import './css/styles.css';
import NavBar from './components/NavBar'
import HomeContent from './components/HomeContent'
import Cooperation from './components/Cooperation'
import AboutUs from './components/AboutUs'
import Services from './components/Services'
import Agents from './components/Agents'
import Suppliers from './components/Suppliers'
import ContactUs from './components/ContactUs'
import MobileDownload from './components/MobileDownload'
import Footer from './components/Footer'

function App() {
  return (
   <>
   <NavBar/>
   <HomeContent/>
   <Cooperation/>
   <AboutUs/>
   <Services/>
   <Agents/>
   <hr className='divider' style={{maxWidth:'100%'}}/>
   <Suppliers/>
   <ContactUs/>
   <MobileDownload/>
   <Footer/>
   </>
  );
}

export default App;
