const Cooperation = () => {
	return (
		<section className="page-section bg-primary" id="corporation">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="text-white mt-0">Cơ Hội Hợp Tác Cùng Chúng Tôi!</h2>
                        <hr className="divider divider-light" />
                        <p className="text-white-75 mb-4">Nếu Quý vị có nhu cầu tìm hiểu về sản phẩm của chúng tôi, về tiềm năng phát triển cũng như cơ hội kinh doanh trong thị trường thì chúng tôi rất sẵn sàng thiết lập một cuộc hẹn để cùng nhau trao đổi và chia sẻ những thông tin bổ ích.</p>
						<p className="text-white-75 mb-4">Trong mối quan hệ với các Nhà đầu tư, Quản lý, Sales Điện tử đại lý và cộng tác viên Online của mình, chúng tôi luôn đề cao sự hợp tác mà trong đó các bên tham gia cùng AHD phải cùng có lợi (win - win) thông qua những chính sách và chương trình tốt nhất dành cho Quý đối tác.</p>
						<p className="text-white-75 mb-4">Ngoài ra, chúng tôi luôn tìm kiếm và mong muốn được hợp tác với các trường hoặc trung tâm đào tạo, các doanh nghiệp và các hãng ở những lĩnh vực có liên quan để cùng triển khai những sự kiện, hoạt động marketing và truyền thông nhằm phát triển thương hiệu của các bên một cách hiệu quả.</p>
						<p className="text-white-75 mb-4">Chúng tôi rất mong nhận được nhiều sự quan tâm và cơ hội hợp tác trong tương lai cùng Quý vị.</p>
                        <a className="btn btn-light btn-xl" href="#contact">Liên Hệ</a>
                    </div>
                </div>
            </div>
        </section>
	)
}
export default Cooperation;