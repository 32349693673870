import { useEffect, useState } from "react"
const Services = () => {
	const [categories, setCategories] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	useEffect(()=>{
		console.log(process.env)
		fetch(`${process.env.REACT_APP_API_URL}/front/category/homepage-categories`)
		.then((res) => res.json())
		.then(json => {
			setIsLoading(false);
			setCategories(json);
			console.log('categories', json);
		})
		.catch(()=>{
			setCategories([]);
			setIsLoading(false);
		})
	}, [])
	return (
		<section className="page-section" id="services">
	<div className="container-fluid px-4 px-lg-5">
		<h2 className="text-center mt-0">Sản Phẩm Tiêu Biểu</h2>
		<hr className="divider" />
		<div className="row gx-4 gx-lg-5 row-products">
			{
				categories.map(item => (
					<div className="col-sm-6 col-lg-4 col-xl-2 text-center" key={item.id}>
						<div className="mt-5">
							<div className="mb-2 text-center"><div><img src={item.pictureModel != null ? item.pictureModel.imageUrl : require('../assets/image_unavailable.png')}
							alt={item.name}  className="img-fluid" loading="lazy"/></div></div>
							<h3 className="h4 mb-2">
								<div style={{color:'#f4623a', fontSize: '1.25rem'}}>{item.name}</div></h3>
							<p className="text-muted mb-0">{item.description}</p>
						</div>
					</div>
				))
			}
		</div>
	</div>
</section>
	)
}
export default Services