import { useEffect, useState } from "react"
const Suppliers = () => {
    const [suppliers, setSuppliers] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	useEffect(()=>{
		console.log(process.env)
        setIsLoading(true);
		fetch(`${process.env.REACT_APP_API_URL}/front/suppliers/list`)
		.then((res) => res.json())
		.then(json => {
			setIsLoading(false);
			setSuppliers(json);
			console.log('suppliers', json);
		})
		.catch(()=>{
			setSuppliers([]);
			setIsLoading(false);
		})
	}, [])
	return (
		<div id="supplier" className="page-section bg-dark text-white">
		    <div className="container-fluid p-0">
				<h2 className="text-center mt-0">Danh sách nhà cung cấp</h2>
                <hr className="divider" />
                <div className="row g-0 mt-5">

                {
				suppliers.map(item => (

                     <div className="col-sm-6 col-lg-4 col-xl-3" style={{padding: 5}} key={item.id}>
                        <div className="agent-box" title={item.name}>
                            <div className="portfolio-box-caption">
                                <div className="project-category">
                                    <span style={{color: '#fff', fontWeight: 700, fontSize: '1.25rem', textTransform:'uppercase'}}>
                                    {item.name}
                                    </span>
                                    {
                                        item.description !== '' && <p style={{fontSize: '0.75rem', fontWeight: 200, fontStyle: 'italic'}}>
                                            {item.description}
                                        </p>
                                    }
                                    </div>
                                <div className="project-name">
                                    Hotline: <span style={{fontStyle: 'italic', fontWeight: 250}}>{item.phone}</span><br />
                                    Địa chỉ: <span style={{fontStyle: 'italic', fontWeight: 250}}>{item.address}</span><br />
                                </div>
                            </div>
                        </div>
                    </div>


                        // <div className="col-sm-6 col-lg-4 col-xl-3" key={item.id}>
                        //     <div className="portfolio-box" title={item.name}>
                        //         <img className="img-fluid" src={item.pictureModels != null && item.pictureModels.length > 0 ? item.pictureModels[0].imageUrl : require('../assets/image_unavailable.png')} alt={item.name}  />
                        //         <div className="portfolio-box-caption">
                        //             <div className="project-category">{item.name}</div>
                        //             <div className="project-name">Mã số thuế: Chưa có thông tin<br />
                        //                 Gmail: Chưa có thông tin<br />
                        //                 Hotline: Chưa có thông tin<br />
                        //                 Địa chỉ: {item.address}<br />
                        //                 Nghành nghề: {item.description}
                        //             </div>
                        //         </div>
                        //     </div>
                        // </div>
                    ))
                }
                </div>
            </div>
        </div>
	)
}
export default Suppliers