const HomeContent = () => {
	return (
		<header className="masthead">
		<div className="container px-4 px-lg-5 h-100">
			<div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
				<div className="col-lg-10 col-xl-8 align-self-end">
					<h1 className="text-white font-weight-bold mt-5">HOAN NGHÊNH QUÝ NHÀ SẢN XUẤT, NHẬP KHẨU, NHÀ VƯỜN, TRẠI CHĂN NUÔI HỢP TÁC CÙNG AHD CÙNG NHAU PHÁT TRIỂN</h1>
					<hr className="divider" />
				</div>
				<div className="col-lg-8 align-self-baseline">
					<p className="text-white-75 mb-5">Chúng tôi Công ty Cổ phần Tập Đoàn Hoàng Minh Trung hoan nghênh mọi sự hợp tác dù đó là với những Hộ kinh Doanh, trại chăn nuôi, nhà vườn, đơn vị sản xuất, hay các đơn vị kinh doanh chuyên nghiệp.</p>
					<a className="btn btn-primary btn-xl" href="#corporation">Tìm Hiểu Thêm</a>
				</div>
			</div>
		</div>
	</header>
	)
}
export default HomeContent;