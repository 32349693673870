import React, { useEffect } from 'react'

const NavBar = (props) => {
	const handleContentLoaded = () => {
		// Navbar shrink function
		var navbarShrink = function () {
		 const navbarCollapsible = document.body.querySelector('#mainNav');
		 if (!navbarCollapsible) {
			 return;
		 }
		 if (window.scrollY === 0) {
			 navbarCollapsible.classList.remove('navbar-shrink')
		 } else {
			 navbarCollapsible.classList.add('navbar-shrink')
		 }
   
	 };
   
	 // Shrink the navbar 
	 navbarShrink();
   
	 // Shrink the navbar when page is scrolled
	 document.addEventListener('scroll', navbarShrink);
   
	 // Collapse responsive navbar when toggler is visible
	 const navbarToggler = document.body.querySelector('.navbar-toggler');
	 const responsiveNavItems = [].slice.call(
		 document.querySelectorAll('#navbarResponsive .nav-link')
	 );
	 responsiveNavItems.map((responsiveNavItem) => {
		 return responsiveNavItem.addEventListener('click', () => {
			 if (window.getComputedStyle(navbarToggler).display !== 'none') {
				 navbarToggler.click();
			 }
		 });
	 });
   
	 };
	 useEffect(() => {
	   handleContentLoaded();
	 }, []);
	return (
		<nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
            <div className="container px-4 px-lg-5">
                <a className="navbar-brand" href="#page-top"><img src={require("../assets/img/logo.png")} alt="AHD" /></a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto my-2 my-lg-0">
                        <li className="nav-item"><a className="nav-link" href="#corporation"><span className="menu-icon"><i className="bi bi-globe2"></i></span><span>Hợp Tác</span></a></li>
                        <li className="nav-item"><a className="nav-link" href="#about"><span className="menu-icon"><i className="bi bi-info-circle-fill"></i></span><span>Về Chúng Tôi</span></a></li>
                        <li className="nav-item"><a className="nav-link" href="#services"><span className="menu-icon"><i className="bi bi-images"></i></span><span>Sản Phẩm</span></a></li>
                        <li className="nav-item"><a className="nav-link" href="#portfolio"><span className="menu-icon"><i className="bi bi-person-lines-fill"></i></span><span>Đại Lý</span></a></li>
						<li className="nav-item"><a className="nav-link" href="#supplier"><span className="menu-icon"><i className="bi bi-collection-fill"></i></span><span>Nhà cung cấp</span></a></li>
                        <li className="nav-item"><a className="nav-link" href="#contact"><span className="menu-icon"><i className="bi bi-envelope-paper-fill"></i></span><span>Liên Hệ</span></a></li>
                        <li className="nav-item"><a className="nav-link" href="#download"><span className="menu-icon"><i className="bi bi-cart4"></i></span><span>Mua Hàng</span></a></li>
                    </ul>
                </div>
            </div>
        </nav>
	);
}
export default NavBar;